<div class="d-flex w-100 h-100">
  <form class="container my-auto" [ngClass]="{ disabled: !location }">
    <div class="row justify-content-center store-container">
      <div class="col-12 col-sm-10 text-center">
        <h2 class="fs-2">SELECCIONA TU PUNTO DE VENTA</h2>
      </div>

      <div class="col-5 text-center">
        <div class="store-container">
          <div class="clickable">
            <img
              [src]="'assets/images/convenience.png'"
              width="100%"
              alt="Imagen tienda"
            />
          </div>

          <h5 class="font-normal text-center mt-sm-3 mx-0 clickable">
            Conveniencia
          </h5>
        </div>
      </div>

      <div
        class="col-11 col-lg-8 px-0 mx-auto mt-2 mt-md-4 pt-md-4"
        data-aos="fade-right"
        *ngIf="!loading"
      >
        <div class="options-container">
          <div class="w-100" *ngFor="let retail of stores; index as sInd">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="storeRetail{{ storeInd }}"
                [id]="'retail' + retail.id"
                [value]="retail.id"
                [(ngModel)]="data.retail_id"
              />

              <label class="form-check-label" for="retail{{ retail.id }}">
                {{ retail.name }}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="col-11 col-lg-7 px-0 mx-auto text-center mt-2 mt-sm-3">
        <div class="form-floating w-100 text-center">
          <input
            type="text"
            class="form-control text-center"
            id="storeNumber"
            placeholder="Nombre o número de la tienda"
            [(ngModel)]="data.store"
            [ngModelOptions]="{ standalone: true }"
            autocomplete="nope"
            name="store-name"
          />

          <label for="storeNumber" class="text-center">
            Número de la tienda
          </label>
        </div>
      </div>

      <div class="col-11 px-0 mx-auto mt-2 mt-sm-3" *ngIf="alertData.active">
        <app-alert [data]="alertData"></app-alert>
      </div>

      <div class="col-11 col-lg-7 px-0 mx-auto mt-3 mt-sm-4">
        <button class="main-btn" (click)="validateStore()" [disabled]="loading">
          SIGUIENTE
        </button>
      </div>
    </div>
  </form>

  <div class="location-alert" *ngIf="!location">
    <div class="container centered">
      <div class="row text-center">
        <div class="col-12">
          <h1 class="text-danger">
            Para continuar por favor da permisos de ubicación y recarga la
            página
          </h1>
        </div>

        <div class="col-12 my-4 my-sm-5">
          <button class="btn btn-light w-50" (click)="reload()">
            <div class="d-flex flex-column">
              <i class="bi bi-arrow-clockwise faa-xl clickable"></i>

              <p class="">Recargar</p>
            </div>
          </button>
        </div>

        <div class="col-12">
          <a
            class="btn btn-danger"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.google.com/search?q={{ encodeUri() }}"
          >
            {{ helpMessage }}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
