export class User {
  name: string = '';
  lastname: string = '';
  email: string = '';
  birthdate: string = '';
  lat: string = '';
  lng: string = '';

  isValid(): boolean {
    const isFilled = this.name !== '' && this.lastname !== '' && this.birthdate !== '';

    return isFilled;
  }
}