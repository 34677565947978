<container-element
  class="d-flex flex-column w-100 h-100 pt-3"
  [ngClass]="loading ? 'loading' : 'show-data'"
  [ngSwitch]="step"
>
  <div class="spinner-border loading-spinner" role="status" *ngIf="loading">
    <span class="visually-hidden">Loading...</span>
  </div>

  <div class="row py-2 py-sm-3 mb-2 mb-sm-3">
    <div class="col-3 col-lg-2 ms-auto main-logos text-center">
      <img src="assets/images/box_1.png" width="85%" alt="regalos" />
    </div>
  
    <div class="col-5 col-sm-6 align-self-end main-logos px-0">
      <img
        src="assets/images/logo_header.png"
        width="90%"
        alt="Logo PALL MALL"
      />
  
      <img src="assets/images/borabora.png" width="90%" alt="regalos" />
    </div>
  
    <div class="col-3 col-lg-2 me-auto main-logos  text-center">
      <img src="assets/images/box_2.png" width="85%" alt="regalos" />
    </div>
  </div>
  
  <app-shop-data
    class="col-12 my-auto px-0"
    *ngSwitchCase="1"
    [loading]="loading"
    (moveView)="validateMoved($event)"
    (showVideo)="playVideo($event)"
    (loaded)="setLoading($event)"
    ></app-shop-data
  >

  <app-questions
    class="col-12 my-auto px-0"
    *ngSwitchCase="2"
    [loading]="loading"
    (loaded)="setLoading($event)"
    (moveView)="handleResult($event)"
    (cancel)="cancel()"
  ></app-questions>
</container-element>

<div
  bsModal
  #videoModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  [config]="{ backdrop: 'static', keyboard: false }"
  (onHide)="stopVideo()"
>
  <div class="modal-dialog modal-xl modal-dialog-centered">
    <div class="modal-content bg-dark">
      <div class="modal-body">
        <video
          id="video"
          src="assets/videos/bat-pall_mall-bora_bora.mp4"
          controls
          (ended)="videoModal.hide()"
          autoplay
        ></video>
      </div>
    </div>
  </div>
</div>
