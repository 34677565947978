export class Question {
  id: number = 0;
  title: string = '';
  answers: Answer[] = [];
}

export class Answer {
  id: number = 0;
  title: string = '';
}

export class UserAnswer {
  id:     number = -1;
  answer: number = -1;
}
