<div class="d-flex flex-column w-100 h-100">
  <div class="row">
    <div class="col-12">
      <app-alert [data]="alertData"></app-alert>
    </div>
  </div>

  <form class="w-100 my-auto">
    <h2 class="text-center px-2 px-sm-4 my-2 my-sm-3 my-md-4 fs-2">
      Responde las siguientes preguntas correctamente
    </h2>

    <div class="text-center qs-number">
      <img
        [src]="
          'assets/images/' + (questionInd < 5 ? questionInd + 1 : 3) + '.png'
        "
        alt="Contador pregunta"
      />
    </div>

    <div
      class="container text-center"
      [ngClass]="qsClass"
      *ngFor="let qs of questions; index as qsInd"
    >
      <div
        class="row justify-content-center h-100"
        *ngIf="questionInd === qsInd"
      >
        <div class="col-sm-10 mx-auto">
          <div class="qs-title">
            <p class="fs-5 fw-lighter m-0">
              {{ qs.title }}
            </p>
          </div>
        </div>

        <div class="col-12 text-center my-sm-3">
          <div class="questions-container">
            <div
              class="question-container text-start"
              *ngFor="let opt of qs.answers"
            >
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="optName"
                  [value]="opt.id"
                  [id]="'opt' + opt.id"
                  [(ngModel)]="answers[qsInd].answer"
                />

                <label class="form-check-label" [for]="'opt' + opt.id">
                  {{ opt.title }}
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 px-0 mt-2 mt-sm-3">
          <div class="row justify-content-evenly">
            <div class="col-6 col-sm-5" *ngIf="questionInd > 0">
              <button
                class="main-btn"
                (click)="animateQs(false)"
                [disabled]="loading"
              >
                ATRAS
              </button>
            </div>

            <div class="col-6 col-sm-5">
              <button
                class="main-btn"
                (click)="animateQs(true)"
                [disabled]="loading || answers[qsInd].answer === -1"
                [title]="
                  answers[qsInd].answer === -1 ? 'Responde la pregunta' : ''
                "
              >
                {{
                  questionInd < questions.length - 1 ? "SIGUIENTE" : "FINALIZAR"
                }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
