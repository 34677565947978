import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private hintKey: string = environment.cookie + '_hints';
  private pKey: string = environment.cookie + '_token';
  private winKey: string = environment.cookie + '_result';
  status: any;
  userName: any;

  constructor() {
    const dataStorage = localStorage.getItem(this.pKey);

    if (dataStorage) {
      let data = dataStorage;
    } else {
      this.clearSession();
    }
  }

  isCompleted(): boolean {
    if (this.status) {
      console.log(this.status);
      
      const res: boolean =
        this.status.profile && this.status.trivia && this.status.poll;

      return res;
    }

    return false;
  }

  isLoggedIn(): boolean {
    return this.token ? true : false;
  }

  get token(): string {
    return localStorage.getItem(this.pKey) ?? '';
  }

  set usetToken(token: string) {
    localStorage.setItem(this.pKey, token);
  }

  get win(): string {
    return localStorage.getItem(this.winKey) ?? 'false';
  }

  set win(win: string) {
    localStorage.setItem(this.winKey, win);
  }

  get hints(): string | null {
    return localStorage.getItem(this.hintKey) ?? null;
  }

  set hints(win: string | null) {
    if (win) {
      localStorage.setItem(this.hintKey, win);
    } else {
      localStorage.removeItem(this.hintKey);
    }
  }

  clearSession() {
    this.status = undefined;
    this.userName = undefined;
    localStorage.clear();
  }
}
