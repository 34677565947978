import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import html2canvas from 'html2canvas';
import JsBarcode from 'jsbarcode';
import { AlertModal, AlertType } from 'src/app/models/Alert.model';
import { UserAnswer } from 'src/app/models/Question.model';
import { Feedback } from 'src/app/models/Score.model';
import { ApiService } from 'src/app/services/api.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-score',
  templateUrl: './score.component.html',
  styleUrls: ['./score.component.scss'],
})
export class ScoreComponent implements OnInit {
  alertData: AlertModal = new AlertModal();
  userName: string = '';
  code: string = '';
  codeUrl: string = '';
  hasCode: boolean = false;
  loading: boolean = true;
  win: boolean = false;
  hints: string[] | null = [];

  constructor(
    private api: ApiService,
    private user: UserService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.hints = this.user.hints ? this.user.hints.split('<separator>') : null;
    console.log(this.hints);

    this.api.call('user/profile').subscribe((response) => {
      this.user.userName = `${response.data.name} ${response.data.lastname}`;
      this.user.status = response.data.status;

      this.userName = this.user.userName ?? 'Nombre';
      this.hasCode = this.user.status.hasCode;

      console.log(this.user.isCompleted());

      if (this.user.isCompleted()) {
        if (!this.hasCode && !this.user.status.code && !this.user.status.url) {
          this.win = true;
        } else {
          this.code = this.user.status.code;
          this.codeUrl = this.user.status.url;
          this.win = true;
        }
      } else {
        this.win = this.user.win !== 'false';
        if (this.user.status && !this.user.status.hasCode) {
          this.win = true;
        }
      }

      this.loading = false;
    });

    if (this.win) {
      this.api.send('winner', {
        event_category: 'enter',
        event_label: 'winner',
        value: true,
      });
    }
  }

  retry() {
    this.user.status.trivia = false;
    this.router.navigateByUrl('/trivia');
  }
}
