<div
  class="code-page"
  [ngClass]="loading ? 'loading' : 'show-data'"
>
  <div class="spinner-border loading-spinner" role="status" *ngIf="loading">
    <span class="visually-hidden">Loading...</span>
  </div>

  <div class="container h-100 d-flex flex-column">
    <div class="row py-2 py-sm-3 mb-2 mb-sm-3">
      <div class="col-3 col-lg-2 ms-auto main-logos text-center">
        <img src="assets/images/box_1.png" width="85%" alt="regalos" />
      </div>
  
      <div class="col-5 col-lg-4 align-self-end main-logos px-0">
        <img
          src="assets/images/logo_header.png"
          width="90%"
          alt="Logo PALL MALL"
        />
  
        <img src="assets/images/borabora.png" width="90%" alt="regalos" />
      </div>
  
      <div class="col-3 col-lg-2 me-auto main-logos text-center">
        <img src="assets/images/box_2.png" width="85%" alt="regalos" />
      </div>
    </div>
  
    <div class="row my-auto">
      <div class="col-12 text-center mb-3">
        <h1 class="mb-0">Código</h1>
      </div>
  
      <div class="col-12 px-0" *ngIf="valid; else noValid">
        <div class="row align-items-center">
          <div class="col-12 mb-2 mb-lg-3">
            <p class="h5 mb-0 text-center">
              Copia éste código en el sitio para reclamar tu premio:
            </p>
          </div>
  
          <div class="col-12 mb-3 mb-lg-4 text-center">
            <input
              id="code"
              type="text"
              class="form-control form-control-lg text-center"
              [value]="uuid"
              readonly
            />
          </div>
  
          <div class="col-12 mb-2 mb-lg-3">
            <p class="h5 mb-0 text-center">
              Haz clic en el siguiente enlace para canjear el codigo:
              <a href="https://www.pointsapp.com.mx/recargas"> Recargas </a>
            </p>
          </div>
  
          <div class="col-8 col-lg-10 mx-auto">
            <img src="assets/images/trophy.png" class="img-fluid" />
          </div>
        </div>
      </div>
  
      <ng-template #noValid>
        <div class="col-12 text-center">
          <p class="h1 text-danger">
            {{ message }}
          </p>
        </div>
      </ng-template>
    </div>
  </div>
</div>
