<div
  class="d-flex flex-column w-100 h-100 pt-2 pt-sm-3"
  [ngClass]="loading ? 'loading' : 'show-data'"
>
  <div class="spinner-border loading-spinner" role="status" *ngIf="loading">
    <span class="visually-hidden">Loading...</span>
  </div>

  <div class="container px-0 m-auto">
    <div class="row justify-content-center text-center" *ngIf="!win">
      <div class="col-12 col-sm-10">
        <h1 class="loser-label mb-0">¡UYYY!</h1>
      </div>

      <div class="col-12 col-sm-10 my-2 my-sm-4">
        <h2 class="fw-normal">Inténtalo de nuevo</h2>
      </div>

      <div class="col-12 mb-2 mb-sm-4">
        <p class="h5 fw-lighter m-0">
          Recuerda que puedes ganar increíbles premios contestando las preguntas
          correctamente
        </p>
      </div>

      <div class="col-12" *ngIf="hints && hints.length > 0">
        <h5>Consideraciones</h5>

        <ol
          class="w-100 transparent-bg text-start list-group list-group-numbered"
        >
          <li class="list-group-item" *ngFor="let hint of hints">{{ hint }}</li>
        </ol>
      </div>

      <div class="col-8 mt-3 mt-sm-5">
        <button class="main-btn" (click)="retry()" [disabled]="loading">
          REINTENTAR
        </button>
      </div>
    </div>

    <div class="row justify-content-center text-center" *ngIf="win">
      <div class="col-12 col-lg-8 pt-2 pt-sm-4 mb-2 mb-sm-4">
        <div class="trophy-container mx-auto">
          <img
            src="assets/images/logo_header.png"
            width="50%"
            class="mb-3"
            alt="Logo PALL MALL"
          />

          <img
            src="assets/images/win.png"
            class="label-img"
            alt="Felicidades"
            width="94%"
          />
        </div>
      </div>

      <div
        class="col-12 col-sm-10 col-lg-8 mx-auto mb-2 mb-sm-4"
        *ngIf="hasCode"
      >
        <div class="card transparent-bg border-secondary text-light py-lg-2">
          <div class="card-body py-2 py-sm-3">
            <p class="mb-0">
              Para canjear tu premio sigue las instrucciones que te hicimos
              llegar a tu correo electrónico
            </p>
          </div>
        </div>
      </div>

      <div class="col-12 px-0 mt-2 mt-sm-auto">
        <img
          class="trophy-img"
          src="assets/images/trophy.png"
          alt="Trofeo ganador"
          width="100%"
        />
      </div>

      <div class="col-sm-10 text-center" *ngIf="!hasCode">
        <h3 class="fw-normal">COMPLETASTE EL 100% DE TU CAPACITACIÓN.</h3>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <app-alert [data]="alertData"></app-alert>
      </div>
    </div>
  </div>
</div>
