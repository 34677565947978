import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-code',
  templateUrl: './code.component.html',
  styleUrls: ['./code.component.scss'],
})
export class CodeComponent {
  alert: boolean = false;
  message: string = '';
  loading: boolean = true;
  valid: boolean = false;
  id: string = '';
  uuid: string = '';

  constructor(private route: ActivatedRoute, private api: ApiService) {}
  // codigo?uid=1&uuid=0dcbc174-1673-4690-a9aa-f48284bdf057

  ngOnInit(): void {
    this.id = this.route.snapshot.queryParamMap.get('uid') ?? '';
    this.uuid = this.route.snapshot.queryParamMap.get('uuid') ?? '';

    if (!this.id || !this.uuid) {
      this.loading = false;

      return;
    }

    this.validate(this.id, this.uuid);
  }

  validate(id: string, uuid: string) {
    this.api.call(`redeem/${id}/${uuid}`).subscribe({
      next: (response) => {
        this.valid = response.status === 200;

        if (!this.valid) {
          this.message = response.message.join(' ');
          this.alert = true;

          console.log(this.message);
        }

        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
      },
    });
  }
}
