<div class="full-content" [ngClass]="loading ? 'loading' : ''">
  <div class="spinner-border loading-spinner" role="status" *ngIf="loading">
    <span class="visually-hidden">Loading...</span>
  </div>

  <button *ngIf="canLogout" class="btn btn-danger logout" (click)="logout()">
    SALIR
  </button>

  <div class="my-app" [ngClass]="winBg ? 'win-bg' : ''" *ngIf="!loading">
    <div class="main-container">
      <router-outlet class="h-100 w-100"></router-outlet>
    </div>

    <app-footer *ngIf="!isRegister"></app-footer>
  </div>
</div>
