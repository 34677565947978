import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UserService } from '../user.service';

@Injectable()
export class ApiInterceptorInterceptor implements HttpInterceptor {

  constructor(private user: UserService, private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
    request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

    let userToken = this.user.token;

    if (userToken != null) {
      request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + userToken) });
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) { }
        return event;
      }),
      catchError((response: HttpErrorResponse) => {
        if (response.error.status == 402 || response.error.status == 404) {
          alert(response.error.message.join('\n'))
        }

        if (response.error.status == 401) {
          this.user.clearSession();
          this.router.navigate(['/trivia']);
        }

        return throwError(response);
      })
    );
  }
}
