export enum AlertType {
  Primary = 'alert-primary',
  Secondary = 'alert-secondary',
  Success = 'alert-success',
  Danger = 'alert-danger',
  Warnings = 'alert-warning',
  Info = 'alert-info',
  Light = 'alert-light',
  Dark = 'alert-dark'
}

interface Alert {
  active: boolean,
  title: string,
  className: AlertType,
  content: string
}

export class AlertModal {
  active: boolean = false;
  title: string = '';
  className: AlertType = AlertType.Info;
  content: string = '';

  set data(data: Alert) {
    this.title = data.title;
    this.active = data.active;
    this.className = data.className;
    this.content = data.content;
  }
}