export class Retail {
  id!: number;
  name!: string;
}

export class Store {
  id!: number;
  name!: string;
  stores: number = 1;

  set storeData(store: any) {
    this.id = store.id ?? '';
    this.name = store.name ?? '';
    this.stores = store.stores ?? 1;
  }
}

export class StoreData {
  retail_id: number = -1;
  store: string = '';
  lat: string | number = '';
  lng: string | number = '';

  isDataValid(): boolean {
    const valid: boolean =
      this.retail_id !== -1 &&
      this.store !== '' &&
      this.lat !== '' &&
      this.lng !== '';

    return valid;
  }
}
