import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginGuard } from './guards/login.guard';
import { PrivateGuard } from './guards/private.guard';
import { RegisterComponent } from './pages/register/register.component';
import { ScoreComponent } from './pages/trivia/score/score.component';
import { TriviaComponent } from './pages/trivia/trivia.component';
import { RedirectComponent } from './components/redirect/redirect.component';
import { CodeComponent } from './pages/code/code.component';

const routes: Routes = [
  { path: 'codigo', component: CodeComponent },
  { path: 'recargas', component: RedirectComponent },
  { path: 'registro', component: RegisterComponent, canActivate: [LoginGuard] },
  { path: 'trivia', component: TriviaComponent, canActivate: [PrivateGuard] },
  {
    path: 'resultados',
    component: ScoreComponent,
    canActivate: [PrivateGuard],
  },
  { path: '**', redirectTo: 'registro' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
