import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ApiService } from 'src/app/services/api.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-trivia',
  templateUrl: './trivia.component.html',
  styleUrls: ['./trivia.component.scss'],
})
export class TriviaComponent {
  loading: boolean = true;
  win: boolean = false;
  step: number = 2;

  @ViewChild('videoModal', { static: false }) videoModal?: ModalDirective;

  constructor(
    private user: UserService,
    private router: Router,
    private api: ApiService
  ) {}

  ngOnInit(): void {
    if (this.user.status) {
      if (this.user.isCompleted()) {
        this.router.navigateByUrl('/resultados');
      } else {
        // this.step = this.user.status.profile ? 2 : 1;
        this.loading = false;
      }
    } else {
      this.api.call('user/profile').subscribe((response) => {
        this.user.userName = `${response.data.name} ${response.data.lastname}`;
        this.user.status = response.data.status;

        if (this.user.isCompleted()) this.router.navigateByUrl('resultados');

        // this.step = 1;
      });
    }

    // ! DEBUG
    this.step = 2;
  }

  playVideo(status: boolean) {
    if (!status) return;

    this.videoModal?.show();
  }

  setLoading(state: boolean) {
    setTimeout(() => {
      this.loading = state;
    }, 0);
  }

  validateMoved(status: boolean) {
    console.debug(status);

    this.api.send('trivia', {
      event_category: 'enter',
      event_label: 'trivia',
      value: true,
    });

    if (status) {
      this.step += 1;
    } else {
      this.step -= 1;
    }
  }

  stopVideo() {
    const video: any = document.getElementById('video');

    this.api.send('video', {
      event_category: 'enter',
      event_label: 'video',
      value: true,
    });

    if (video) {
      video.pause();
      video.currentTime = 0;
    }
  }

  cancel() {
    this.step -= 1;
  }

  handleResult(win: boolean) {
    console.log(win);
    
    this.router.navigateByUrl('/resultados');
  }
}
