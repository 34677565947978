import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';

import { AlertModal, AlertType } from 'src/app/models/Alert.model';
import { User } from 'src/app/models/Register.model';
import { UserService } from 'src/app/services/user.service';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  alertData: AlertModal = new AlertModal();
  hide: boolean = true;
  loading: boolean = false;
  terms: boolean = false;
  user: User = new User();
  setTimer: boolean = false;

  months: string[] = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];
  days: any = Array(31);
  years: any = Array(120);
  minYear: number = 0;

  day: string = '-1';
  month: string = '-1';
  year: string = '-1';

  @ViewChild('termsModal', { static: false }) termsModal?: ModalDirective;

  constructor(
    private router: Router,
    private api: ApiService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.minYear = new Date().getFullYear() - 17;
  }

  openModal() {
    this.termsModal?.show();
  }

  register() {
    this.user.birthdate =
      this.year +
      '-' +
      this.month.padStart(2, '0') +
      '-' +
      this.day.padStart(2, '0');

    if (!this.terms || !this.user.isValid()) {
      this.alertData.data = {
        active: true,
        title: '',
        className: AlertType.Warnings,
        content: `Llena todos los campos!`,
      };

      this.setTimer = !this.setTimer;
      return;
    }

    if (this.year == '-1' || this.month == '-1' || this.day == '-1') {
      this.alertData.data = {
        active: true,
        title: '',
        className: AlertType.Warnings,
        content: `¡Ingresa una fecha válida!`,
      };

      return;
    }

    this.loading = true;

    this.api.call('register', 'post', this.user).subscribe({
      next: (response) => {
        if (response.status == 200) {
          this.userService.usetToken = response.data.token ?? '';
          this.router.navigateByUrl('trivia');
          this.api.send('register', {
            event_category: 'enter',
            event_label: 'register',
            value: true,
          });
        } else {
          this.alertData.data = {
            active: true,
            title: 'Error: ',
            className: AlertType.Danger,
            content: response.message.join('\n'),
          };
        }

        this.loading = false;
      },
      error: (error) => {
        this.alertData.data = {
          active: true,
          title: 'Error: ',
          className: AlertType.Danger,
          content: error.join('\n'),
        };

        this.loading = false;
      },
    });
  }
}

@Component({
  selector: 'app-terms',
  styleUrls: ['./register.component.scss'],
  templateUrl: './terms.html',
})
export class AppTerms {}
