<div class="terms-content-text">
  <h5>ACCESIBILIDAD</h5>

  <p>
    British American Tobacco M&eacute;xico Comercial, S.A. de C.V. (en adelante
    BAT), est&aacute; comprometida a garantizar una plataforma digital accesible
    para todos los usuarios mayores de 18 a&ntilde;os consumidores de nicotina.
    Si tienes alguna pregunta o comentario sobre la accesibilidad de esta
    plataforma, por favor cont&aacute;ctanos.
  </p>
  <p>
    Los presentes T&eacute;rminos y Condiciones regulan el uso de la plataforma
    &ldquo;Bora Bora Sunbreak&rdquo; creada por British American Tobacco
    M&eacute;xico Comercial, S.A. de C.V. Cada vez que una persona acceda a la
    plataforma, o utilice los beneficios ofrecidos, adquiere la calidad de
    usuario de la plataforma (&ldquo;usuario&rdquo;), en cuyo caso acepta
    sujetarse a los presentes T&eacute;rminos y Condiciones, y se adhiere
    plenamente a la totalidad de si contenido y alcance.
  </p>
  <p>
    Cualquier persona que no acepte los presentes t&eacute;rminos y condiciones
    para el uso de la plataforma &ldquo;Bora Bora Sunbreak&rdquo;, los cuales
    todas y cada de sus disposiciones tienen car&aacute;cter obligatorias y
    vinculantes, deber&aacute; abstenerse de utilizar los servicios brindados
    por la plataforma &ldquo;Bora Bora Sunbreak&rdquo;.
  </p>
  <p><strong>CONDICIONES DE USO DE PLATAFORMA</strong></p>
  <ol>
    <li>INTRODUCCI&Oacute;N</li>
  </ol>
  <ul>
    <li>
      Por favor aseg&uacute;rate de haber le&iacute;do los presentes
      T&eacute;rminos y Condiciones (&ldquo;T&eacute;rminos y
      Condiciones&rdquo;), prestando especial atenci&oacute;n a las limitaciones
      de nuestra responsabilidad, ya que &eacute;stos incluyen t&eacute;rminos
      importantes que se aplican cada vez que visitas esta plataforma digital.
    </li>
    <li>
      Puedes imprimir una copia de estos T&eacute;rminos o guardarlos para
      futuras referencias. Estos T&eacute;rminos y Condiciones prevalecen en
      caso de conflicto o inconsistencia con cualquier otra comunicaci&oacute;n.
    </li>
  </ul>
  <ol start="2">
    <li>INFORMACI&Oacute;N SOBRE NOSOTROS</li>
  </ol>
  <ul>
    <li>
      Para contactarnos, ll&aacute;manos al n&uacute;mero 01-800-400-5000; o
      escr&iacute;benos por correo postal a la direcci&oacute;n: Av. Francisco
      I. Madero, n&uacute;mero 2750, Col. Centro Monterrey, C.P. 64000, en
      Monterrey, Nuevo Le&oacute;n, M&eacute;xico.
    </li>
  </ul>
  <ol start="3">
    <li>RESPONSABILIDAD</li>
  </ol>
  <ul>
    <li>
      Entiendes y aceptas que nosotros (incluyendo empresas relacionadas,
      subsidiarias, afiliados, funcionarios, directores, agentes y empleados) no
      seremos responsables por da&ntilde;os, afectaciones, agravio (incluyendo
      negligencia) o de cualquier otro modo por: (i) la p&eacute;rdida de
      ganancias o utilidades de cualquier naturaleza; (ii) la destrucci&oacute;n
      de datos; (iii) p&eacute;rdidas intangibles; o (iv) cualquier da&ntilde;o
      indirecto, incidental, especial o consecuente, que surja de o en
      conexi&oacute;n con estos T&eacute;rminos y Condiciones y/o con el uso o
      imposibilidad de uso de esta plataforma digital, incluso si nos hubieras
      informado de la posibilidad de tales p&eacute;rdidas.
    </li>
    <li>
      Entiendes y aceptas que nosotros (incluyendo nuestra casa matriz, empresas
      relacionadas, subsidiarias, afiliados, funcionarios, directores, agentes y
      empleados) no seremos responsables por da&ntilde;os, afectaciones, agravio
      (incluyendo negligencia), no seremos responsables por da&ntilde;os morales
      y patrimoniales ocasionados en materia de Propiedad Industrial y Derechos
      de Autor.
    </li>
    <li>
      Entiendes y aceptas que el material que se pueda llegar a crear, producir,
      grabar, publicar y/o reproducir dentro de la plataforma digital
      estar&aacute;n libres de todos los derechos de en materia de propiedad
      intelectual, incluidos los derechos de autor, las marcas comerciales,
      copyright, y derechos audiovisuales o sonoros.
    </li>
  </ul>
  <ol start="4">
    <li>FUERZA MAYOR</li>
  </ol>
  <ul>
    <li>
      Nosotros no seremos responsables de ninguna manera por la p&eacute;rdida,
      da&ntilde;o o gastos en los que incurras, que surjan directa o
      indirectamente de cualquier falta o retraso en el cumplimiento de
      cualquiera de nuestras obligaciones en virtud de estos T&eacute;rminos y
      Condiciones, causados por cualquier circunstancia fuera de nuestro
      control, que incluyen, pero no se limitan, al disturbios sociales,
      accidentes, fallas o da&ntilde;os en maquinaria o equipos, incendios,
      inundaciones, huelgas u otros conflictos laborales (que involucren o no la
      participaci&oacute;n de nuestros empleados) o la escasez de materiales o
      de combustible en el mercado existente en el momento en el que se acepta
      el pedido, la interferencia legislativa o administrativa.
    </li>
    <li>
      Si tales circunstancias ocurren y afectan el desempe&ntilde;o de nuestras
      obligaciones, nos pondremos en contacto contigo tan pronto como sea
      razonablemente posible para que seas notificado y nuestras obligaciones
      ser&aacute;n suspendidas. El tiempo para desempe&ntilde;ar nuestras
      obligaciones se extender&aacute; por la duraci&oacute;n de estas
      circunstancias.
    </li>
    <li>
      BAT no se hace responsable por la falta de exactitud, retrasos, falla
      parcial o total, lentitud de la conexi&oacute;n, transmisi&oacute;n de
      virus o cualquier otra falla que impida el correcto funcionamiento de esta
      plataforma digital.
    </li>
    <li>
      Nada en estos T&eacute;rminos y Condiciones limita o excluye nuestra
      responsabilidad por malinterpretaci&oacute;n fraudulenta, por muerte o
      lesi&oacute;n personal resultado de nuestra negligencia o la negligencia
      de nuestros agentes o empleados o por cualquier otra responsabilidad que
      no puede ser limitada o excluida por la Ley.
    </li>
  </ul>
  <ol start="5">
    <li>SOBRE EL USUARIO</li>
  </ol>
  <ul>
    <li>
      El Usuario se compromete a: (i) No retirar nota de derechos de autor o
      marca registrada; (ii) No reproducir y/o modificar, las marcas, recetas,
      pasos de preparaci&oacute;n de la plataforma &ldquo;Bora Bora
      Sunbreak&rdquo; sin el pago previo de derechos de autor; (iii) Intentar
      obtener un acceso no autorizado o da&ntilde;ar cualquier aspecto de la
      plataforma &ldquo;Bora Bora Sunbreak&rdquo;, sus plataformas, sus sistemas
      o redes relacionados.
    </li>
    <li>
      El Usuario es responsable de proteger la confidencialidad de la
      informaci&oacute;n de acceso, y ser&aacute; responsable de todo uso de su
      cuenta de usuario.
    </li>
    <li>
      El Usuario no puede compartir o transferir su cuenta de usuario o la
      informaci&oacute;n de acceso con cualquiera que no est&eacute;
      expresamente establecido en este documento u autorizado expresamente por
      el Usuario.
    </li>
    <li>
      Cualquier acceso no autorizado, uso, distribuci&oacute;n o transferencia
      de su cuenta de usuario o informaci&oacute;n puede resultar en que se
      genere suspensi&oacute;n, terminaci&oacute;n o cancelaci&oacute;n de su
      cuenta de usuario y su acceso y uso de los Servicios en la plataforma
      &ldquo;Bora Bora Sunbreak&rdquo;.
    </li>
    <li>
      El Usuario no puede realizar cualquier intento por su parte de perturbar,
      alentar o promover la interrupci&oacute;n de los servicios puede ser una
      violaci&oacute;n de las leyes penales y civiles.
    </li>
    <li>
      El Usuario, de la plataforma &ldquo;Bora Bora Sunbreak&rdquo; acepta que
      no violar&aacute; cualquier ley, norma o reglamento en relaci&oacute;n con
      su uso de los Servicios, ni tampoco interrumpir o intentar interrumpir el
      funcionamiento de los Servicios de cualquier forma sin limitaci&oacute;n.
    </li>
  </ul>
  <p>
    Con la finalidad de usar correctamente la plataforma &ldquo;Bora Bora
    Sunbreak&rdquo; y para satisfacci&oacute;n de &ldquo;El Usuario&rdquo;,
    &ldquo;BAT&rdquo; dar&aacute; de alta a &ldquo;El Usuario&rdquo; en su base
    de datos de Usuarios Registrados; la cuenta ser&aacute; generada con nombre
    y apellido, g&eacute;nero, direcci&oacute;n de correo electr&oacute;nico y
    n&uacute;mero de tienda a la que pertenece. De igual manera, el sistema de
    la plataforma &ldquo;Bora Bora Sunbreak&rdquo; arrojar&aacute; la
    acreditaci&oacute;n de validaci&oacute;n de seguridad correspondiente.
  </p>
  <p>
    El Usuario es responsable de proteger la confidencialidad de la
    informaci&oacute;n de acceso, y ser&aacute; responsable de todo uso de su
    cuenta de usuario.
  </p>
  <ol start="6">
    <li>DERECHOS DE AUTOR Y PROPIEDAD INTELECTUAL</li>
  </ol>
  <ul>
    <li>
      Cualquier contenido de la plataforma &ldquo;Bora Bora Sunbreak&rdquo;
      derivada de los servicios de BAT no puede ser copiado, reproducido,
      republicado, enviado, transmitido, transcrito, traducido, almacenado,
      alterado, descargado o distribuido en ning&uacute;n medio inventado o por
      inventar, ni por ning&uacute;n motivo, a excepci&oacute;n de la compra de
      la licencia de uso correspondiente, misma que ser&aacute; s&oacute;lo para
      los fines antes descritos, de forma &uacute;nica.
    </li>
  </ul>
  <ol start="7">
    <li>VARIOS</li>
  </ol>
  <ul>
    <li>
      Si alguno de estos T&eacute;rminos es considerado como ilegal,
      inv&aacute;lido o no ejecutable, en parte o en su totalidad, por alguna
      autoridad competente, esto no afectar&aacute; la validez del resto de los
      T&eacute;rminos y Condiciones, los cuales continuar&aacute;n siendo
      v&aacute;lidos y ejecutables a la m&aacute;xima extensi&oacute;n permitida
      por la ley.
    </li>
    <li>
      Nosotros podemos asignar o transferir nuestros derechos y obligaciones
      bajo estos T&eacute;rminos y Condiciones a cualquier
      compa&ntilde;&iacute;a dentro de nuestro grupo, sin tu consentimiento. No
      puedes asignar o transferir ning&uacute;n derecho u obligaci&oacute;n que
      tenga bajo estos T&eacute;rminos a ninguna otra parte sin previo
      consentimiento nuestro por escrito.
    </li>
    <li>
      Estos T&eacute;rminos y Condiciones son entre el usuario y BAT. Excepto
      con relaci&oacute;n a una compa&ntilde;&iacute;a dentro de nuestro grupo,
      ninguna otra persona puede ejercer ninguno de estos t&eacute;rminos.
    </li>
    <li>
      Nosotros podemos cambiar, modificar o revisar estos T&eacute;rminos y
      Condiciones en cualquier momento. Es tu responsabilidad revisar que has
      le&iacute;do y est&aacute;s de acuerdo con los &uacute;ltimos
      T&eacute;rminos en esta plataforma digital.
    </li>
    <li>
      En cualquier momento, forma, bajo su responsabilidad, BAT se reserva el
      derecho de suspender, interrumpir o dejar de operar la plataforma
      &ldquo;Bora Bora Sunbreak&rdquo;, por diversos motivos que deriven del
      proceso de operaci&oacute;n de sus derivados.
    </li>
    <li>
      Los t&iacute;tulos son &uacute;nicos y est&aacute;n dise&ntilde;ados para
      separar el contenido correctamente.
    </li>
  </ul>
  <p><strong>ACTIVIDADES DENTRO DE BORA BORA SUNBREAK</strong></p>
  <p>
    Al participar en esta actividad de generaci&oacute;n de c&oacute;digos,
    aceptas estar sujeto a estos T&eacute;rminos y Condiciones, y ser&aacute;
    considerado como tal. Si no aceptas estar sujeto a estos T&eacute;rminos y
    Condiciones, favor de no participar en esta actividad de generaci&oacute;n
    de c&oacute;digos.
  </p>
  <p>
    No hay cuota de inscripci&oacute;n ni compra necesaria para participar en
    esta actividad de generaci&oacute;n de c&oacute;digos.
  </p>
  <p><strong>PARTICIPANTES ELEGIBLES</strong></p>
  <p>
    La actividad de generaci&oacute;n de c&oacute;digos est&aacute; abierta a
    todas las personas que cumplan los dos criterios siguientes: i) Mayores de
    18 (dieciocho) a&ntilde;os, excepto los empleados de BAT y; ii) Consumidores
    de productos con nicotina, ("Participantes elegibles").
  </p>
  <p>
    BAT se reserva el derecho de rechazar la participaci&oacute;n de, o cesar la
    participaci&oacute;n continua de, una persona que considere haber ingresado
    a las actividades, violando estos T&eacute;rminos y Condiciones. BAT se
    reserva el derecho de exigir a los participantes que presenten prueba de
    elegibilidad para participar en cualquier momento durante el Concurso.
  </p>
  <p>
    Todas las pautas y pr&aacute;cticas habituales detr&aacute;s de la
    Prevenci&oacute;n de Acceso Juvenil (YAP) se aplicar&aacute;n a este
    concurso:
  </p>
  <ul>
    <li>
      Bora Bora Sunbreak es una plataforma restringida para Clientes de BAT
      mayores de edad, por lo tanto, solo los mayores de 18 (dieciocho) pueden
      seguir, por lo que solo los mayores de 18 (dieciocho) estar&aacute;n
      expuestos a esta actividad de generaci&oacute;n de c&oacute;digos.
    </li>
    <li>No se mostrar&aacute;n usuarios con nombres de perfil ofensivos.</li>
  </ul>
  <p><strong>DURACI&Oacute;N DE ACTIVIDAD</strong></p>
  <p>
    La fecha de apertura para participar en esta actividad de generaci&oacute;n
    de c&oacute;digos ser&aacute; el lunes 01 de enero de 2024 a las 12:01 a.m.
    hasta agotar existencias.
  </p>
  <p><strong>C&Oacute;MO PARTICIPAR</strong></p>
  <ol>
    <li>
      Usa la c&aacute;mara de t&uacute; tel&eacute;fono m&oacute;vil para entrar
      a la plataforma a trav&eacute;s de un c&oacute;digo QR.
    </li>
    <li>Ingresa tus datos como usuario (nombre, correo, edad).</li>
    <li>Aceptar tener 18 a&ntilde;os cumplidos.</li>
    <li>Leer y aceptar los t&eacute;rminos y condiciones.</li>
    <li>Ver el video de capacitaci&oacute;n.</li>
    <li>Responder las 5 (cinco) preguntas correctamente.</li>
    <li>Contestar correctamente todas las preguntas para ganar.</li>
    <li>
      El premio ser&aacute; enviado al correo registrado en forma de
      c&oacute;digo para redimir en la p&aacute;gina.
    </li>
    <li>El ganador canjea el c&oacute;digo.</li>
  </ol>
  <p><strong>EL C&Oacute;DIGO GENERADO</strong></p>
  <p>
    El c&oacute;digo generado sirve para adquirir una recarga de saldo con un
    valor de $60 MN (sesenta pesos 00/100 Moneda Nacional), aplicable para la
    compa&ntilde;&iacute;a telef&oacute;nica de uso del ganador. El
    c&oacute;digo es el indicado y no se ofrecer&aacute; efectivo u otras
    alternativas. El c&oacute;digo no es transferible. La generaci&oacute;n de
    c&oacute;digos est&aacute; sujeta a disponibilidad y el BAT se reserva el
    derecho de sustituir cualquier c&oacute;digo por otro de valor equivalente
    sin previo aviso.
  </p>
  <p>
    Si todos o alg&uacute;n ganador se niega(n) a aceptar su c&oacute;digo, se
    considerar&aacute; que ha(n) renunciado al c&oacute;digo, sin que le(s)
    asista derecho a presentar reclamo por indemnizaci&oacute;n contra BAT.
  </p>
  <p>
    De igual forma se proceder&aacute; si una vez aceptado el c&oacute;digo,
    alguno o todos los ganadores se niega(n) a recibirlo o a culminar con el
    tr&aacute;mite correspondiente, en estos casos BAT por los medios que
    considere oportunos documentar&aacute; lo ocurrido.
  </p>
  <p>
    Previa a la participaci&oacute;n en la Actividad, los participantes han
    aceptado estos t&eacute;rminos y condiciones; se entiende que cada ganador
    conoce de la existencia de las restricciones, condiciones y limitaciones de
    la Actividad, el beneficio y la participaci&oacute;n para todos los efectos
    jur&iacute;dicos consecuentes; incluyendo la documentaci&oacute;n necesaria
    para poder disfrutar del mismo.
  </p>
</div>
