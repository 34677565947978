<div class="d-flex w-100 h-100">
  <div class="container h-100 m-auto">
    <div class="row h-100 text-center">
      <div class="col-12 mt-auto pt-4">
        <h1 class="title fs-1">
          ¿Estás listo para comenzar la capacitación de LONDON Twilight más esperada?
        </h1>
      </div>

      <div class="offset-1 col-10 my-4">
        <p class="fs-4">A partir del 13 de marzo de 2023 podrás vivir la experiencia.</p>
      </div>
      
    </div>
  </div>
</div>