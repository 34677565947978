import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertModal } from 'src/app/models/Alert.model';
import { StoreData, Store } from 'src/app/models/Shop.model';
import { ApiService } from 'src/app/services/api.service';
import { UserService } from 'src/app/services/user.service';
import { AlertType } from '../../../models/Alert.model';

@Component({
  selector: 'app-shop-data',
  templateUrl: './shop-data.component.html',
  styleUrls: ['./shop-data.component.scss'],
})
export class ShopDataComponent implements OnInit {
  alertData: AlertModal = new AlertModal();
  data: StoreData = new StoreData();
  qsClass: string = 'show';
  step: number = 0;
  stores: Store[] = [];
  storeInd: number = 0;
  imagesArr: string[] = ['convenience.png', 'wholesale.png', 'traditional.png'];

  helpMessage: string = '';

  location: boolean = true;

  @Input() loading: boolean = true;
  @Output() loaded = new EventEmitter<boolean>();
  @Output() moveView = new EventEmitter<boolean>();
  @Output() showVideo = new EventEmitter<boolean>();

  constructor(private api: ApiService, private user: UserService) {}

  ngOnInit() {
    this.loaded.emit(true);

    this.api.call('catalogs/retails').subscribe({
      next: (response) => {
        if (response.status == 200) {
          response.data.map((store: any) => {
            const newstore = new Store();
            newstore.storeData = store;
            this.stores.push(newstore);
          });
        } else {
          this.alertData.data = {
            active: true,
            title: 'Error:',
            className: AlertType.Danger,
            content: response.message.join('\n'),
          };
        }

        this.getLocation();
      },
      error: (error) => {
        this.alertData.data = {
          active: true,
          title: 'Error:',
          className: AlertType.Danger,
          content: error.message.join('\n'),
        };

        this.loaded.emit(false);
      },
    });
  }

  getDevice(): string {
    const userAgent = navigator.userAgent.toLowerCase();

    if (userAgent.includes('android')) {
      return 'Android';
    } else if (userAgent.includes('windows')) {
      return 'Windows';
    } else if (userAgent.includes('macintosh')) {
      return 'Mac';
    } else if (userAgent.includes('iphone')) {
      return 'iPhone';
    } else {
      return '';
    }
  }

  getBrowser(): string {
    const userAgent = window.navigator.userAgent.toLowerCase();

    if (userAgent.includes('chrome')) {
      return ' en Chrome ';
    } else if (userAgent.includes('safari')) {
      return ' en Safari ';
    } else if (userAgent.includes('firefox')) {
      return ' en Firefox ';
    } else {
      return '';
    }
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.location = true;
          this.data.lat = String(position.coords.latitude);
          this.data.lng = String(position.coords.longitude);
          this.loaded.emit(false);

          setTimeout(() => {
            this.showVideo.emit(true);
          }, 500);
        },
        (error) => {
          console.error(error.message);

          this.helpMessage = `¿Cómo dar permisos de ubicación${this.getBrowser()}${this.getDevice()}?`;

          this.location = false;
          this.showVideo.emit(false);
          this.loaded.emit(false);
        },
        {
          enableHighAccuracy: true,
          maximumAge: 10000,
          timeout: 30000,
        }
      );
    } else {
      alert('Ubicación no soportada.');
      this.location = false;
      this.showVideo.emit(false);
      this.loaded.emit(false);
    }
  }

  encodeUri() {
    return encodeURIComponent(this.helpMessage);
  }

  reload() {
    window.location.reload();
  }

  validateStore() {
    if (!this.data.isDataValid()) {
      this.alertData.data = {
        active: true,
        title: 'Error:',
        className: AlertType.Danger,
        content: `¡Llene todos los campos!`,
      };

      return;
    }

    this.api.call('user/retail', 'post', this.data).subscribe({
      next: (response) => {
        if (response.status == 200) {
          this.user.status.profile = true;
          this.loaded.emit(false);
          this.moveView.emit(true);
        } else {
          this.alertData.data = {
            active: true,
            title: '',
            className: AlertType.Danger,
            content: response.message.join('\n'),
          };

          this.loaded.emit(false);
        }
      },
      error: (error: any) => {
        this.loaded.emit(false);

        this.alertData.data = {
          active: true,
          title: 'Error:',
          className: AlertType.Danger,
          content: error,
        };
      },
    });
  }
}
