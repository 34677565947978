import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertModal, AlertType } from 'src/app/models/Alert.model';
import { Answer, Question } from 'src/app/models/Question.model';
import { ApiService } from 'src/app/services/api.service';
import { UserService } from 'src/app/services/user.service';
import { UserAnswer } from '../../../models/Question.model';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss'],
})
export class QuestionsComponent implements OnInit {
  alertData: AlertModal = new AlertModal();
  answers: UserAnswer[] = [];
  qsClass: string = '';
  questionInd: number = 0;
  questions: Question[] = [];

  @Input() loading: boolean = true;
  @Output() cancel = new EventEmitter<boolean>();
  @Output() loaded = new EventEmitter<boolean>();
  @Output() moveView = new EventEmitter<boolean>();

  constructor(private api: ApiService, private user: UserService) {}

  ngOnInit() {
    this.user.hints = null;
    this.loaded.emit(true);

    this.api.call('game').subscribe({
      next: (response) => {
        console.log('fallo: ', response);

        if (response.status == 200) {
          this.questions = response.data;

          if (this.questions && this.questions.length > 0) {
            this.answers = this.questions.map((question) => {
              const nAswer = new UserAnswer();
              nAswer.id = question.id;
              return nAswer;
            });
          }
        } else {
          this.alertData.data = {
            active: true,
            title: 'Error:',
            className: AlertType.Danger,
            content: response.message.join('\n'),
          };
        }

        this.loaded.emit(false);
      },
      error: (error) => {
        this.alertData.data = {
          active: true,
          title: 'Error:',
          className: AlertType.Danger,
          content: error.message,
        };

        this.loaded.emit(false);
      },
    });
  }

  cancelEvent() {
    this.cancel.emit(true);
  }

  animateQs(next: boolean) {
    const finished = this.questionInd >= this.questions.length - 1;

    if (finished && next) {
      this.finishQuestions();
    } else {
      this.qsClass = 'next-question';

      setTimeout(() => {
        this.qsClass = 'prev-question';

        next ? this.nextQuestion() : this.prevQuestion();
      }, 400);
    }
  }

  prevQuestion() {
    if (this.questionInd == 0) return;

    this.questionInd -= 1;
  }

  nextQuestion() {
    if (this.questionInd >= this.questions.length - 1) return;

    this.questionInd += 1;
  }

  finishQuestions() {
    this.loaded.emit(true);
    const params = { answers: this.answers };
    this.user.hints = null;

    this.api.call('game', 'post', params).subscribe({
      next: (response) => {
        console.log(response);

        if (response.status !== 200 && response.data.hints) {
          this.user.hints = response.data.hints.join('<separator>');
        }

        this.user.status.trivia = true;
        this.user.win = String(response.status == 200);
        this.moveView.emit(response.status == 200);
        this.loaded.emit(true);
      },
      error: (error) => {
        this.alertData.data = {
          active: true,
          title: 'Error:',
          className: AlertType.Danger,
          content: error,
        };

        this.loaded.emit(false);
      },
    });
  }
}
