<div class="w-100 h-100 d-flex">
  <div class="container my-auto">
    <form class="row justify-content-center w-100 text-center">
      <div class="col-6 mx-auto gift-logo my-2 my-sm-3">
        <img src="assets/images/gift.png" width="100%" alt="regalos" />
      </div>

      <div class="col-11 col-sm-10 px-0">
        <app-alert [data]="alertData" [timer]="setTimer"></app-alert>
      </div>

      <div class="col-11 col-sm-10 px-0 mb-2 mb-sm-3">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            placeholder="Nombre(s)"
            name="name"
            [(ngModel)]="user.name"
          />

          <label for="mail">Nombre(s)</label>
        </div>
      </div>

      <div class="col-11 col-sm-10 px-0 mb-2 mb-sm-3">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            placeholder="Apellidos"
            name="lastname"
            [(ngModel)]="user.lastname"
          />

          <label for="mail">Apellidos</label>
        </div>
      </div>

      <div class="col-11 col-sm-10 px-0 mb-2 mb-sm-3">
        <div class="form-floating">
          <input
            type="email"
            class="form-control"
            placeholder="Mail"
            name="mail"
            [(ngModel)]="user.email"
          />

          <label for="mail">Mail</label>
        </div>
      </div>

      <div class="col-11 col-sm-10 px-0 mb-2 text-start">
        <label class="mb-2" for="birthdate">Fecha de nacimiento</label>

        <div class="row p-0 m-0" name="birthdate">
          <div class="col-4 ps-0">
            <div class="form-floating">
              <select
                class="form-select"
                name="day"
                [(ngModel)]="day"
                [ngModelOptions]="{ standalone: true }"
              >
                <option value="-1">Día</option>
                <option
                  [value]="ind + 1"
                  *ngFor="let day of days; index as ind"
                >
                  {{ ind + 1 }}
                </option>
              </select>

              <label for="floatingSelect">Día</label>
            </div>
          </div>

          <div class="col-4 px-0">
            <div class="form-floating">
              <select
                class="form-select"
                name="month"
                [(ngModel)]="month"
                [ngModelOptions]="{ standalone: true }"
              >
                <option value="-1">Mes</option>
                <option
                  [value]="ind + 1"
                  *ngFor="let month of months; index as ind"
                >
                  {{ month }}
                </option>
              </select>

              <label for="floatingSelect">Mes</label>
            </div>
          </div>

          <div class="col-4 pe-0">
            <div class="form-floating">
              <select
                class="form-select"
                name="year"
                [(ngModel)]="year"
                [ngModelOptions]="{ standalone: true }"
              >
                <option value="-1">Año</option>
                <option
                  [value]="minYear - ind"
                  *ngFor="let year of years; index as ind"
                >
                  {{ minYear - ind }}
                </option>
              </select>

              <label for="floatingSelect">Año</label>
            </div>
          </div>
        </div>
      </div>

      <div class="col-11 col-sm-10 px-0 my-2 my-sm-4">
        <div class="form-check mx-auto d-flex">
          <input
            class="form-check-input my-auto me-2"
            type="checkbox"
            name="terms"
            [(ngModel)]="terms"
          />
          <label class="form-check-label align-self-center">
            <span class="clickable terms-text" (click)="termsModal.show()"
              >Acepto términos y condiciones</span
            >
          </label>
        </div>
      </div>

      <div class="col-8 col-sm-6">
        <button
          type="button"
          class="main-btn"
          (click)="register()"
          [disabled]="loading"
        >
          SOY MAYOR DE 18 AÑOS
        </button>
      </div>
    </form>
  </div>

  <div
    bsModal
    #termsModal="bs-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
      <div class="modal-content text-dark">
        <div class="modal-header bg-primary text-light">
          <h4 id="dialog-sizes-name1" class="modal-title pull-left">
            Términos y condiciones
          </h4>
          <button
            type="button"
            class="btn btn-light btn-close pull-right"
            (click)="termsModal.hide()"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <app-terms></app-terms>

          <div class="row justify-content-center">
            <div class="col-6 my-3 text-center">
              <button class="btn btn-primary w-100" (click)="termsModal.hide()">
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
