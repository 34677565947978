import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';

declare var gtag: Function;

class Response {
  data: any;
  status!: number;
  code!: number;
  message: string[] = [];
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient, protected router: Router, private userSession: UserService) { }

  private built(service: string): string {
    return environment.endpoint + service;
  }

  private parse(data: any): Response {
    let response = new Response();
    response.status = data.status;
    response.message = data.message;
    response.code = data.code;
    response.data = data.data;

    if (response.status == 402) {
      alert(response.message.join('\n'));
    }

    return response;
  }

  call<T>(service: string, method = 'get', params: any = {}): Observable<Response> {
    let serviceParams = method == 'get' ? { params: params } : params;

    if (method == 'post') {
      return this.http.post<Response>(this.built(service), serviceParams).pipe(
        map((data: any) => {
          return this.parse(data);
        })
      );
    } else if (method == 'put') {
      return this.http.put<Response>(this.built(service), serviceParams).pipe(
        map((data: any) => {
          return this.parse(data);
        })
      );
    } else if (method == 'delete') {
      return this.http.delete<Response>(this.built(service), serviceParams).pipe(
        map((data: any) => {
          return this.parse(data);
        })
      );
    } else {
      return this.http.get<Response>(this.built(service), serviceParams).pipe(
        map((data: any) => {
          return this.parse(data);
        })
      );
    }
  }

  send(name: string, params: any) {
    if (environment.production) {
      try {
        gtag('event', name, params);
      } catch (err) {
        console.error(err);

      }
    }
  }

  download(service: string) {
    const options = {
      observe: "response" as 'body',
      responseType: 'blob' as 'json',
    };

    this.http.get<HttpResponse<any>>(this.built(service), options).subscribe((response: any) => {
      if (response.body.type == 'application/json') {
        let reader = new FileReader();

        reader.addEventListener('loadend', (e: any) => {
          let json = JSON.parse(e.srcElement.result);
          alert(json.message.join('\n'));
        });

        reader.readAsText(response.body);

        return;
      }

      let hdrs = response.headers.get('Content-Disposition');
      let file = 'document';
      if (hdrs != null) {
        var split = hdrs.split('filename=');
        if (split.length > 0) {
          file = split[1].split('"').join('');
        }
      }

      var u = window.URL.createObjectURL(response.body);
      var l = document.createElement('a');
      l.href = u;
      l.download = file;
      l.click();
    });
  }
}