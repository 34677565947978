import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';

import { AlertComponent } from './components/alert/alert.component';
import { ApiInterceptorInterceptor } from './services/interceptor/api-interceptor.interceptor';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { QuestionsComponent } from './pages/trivia/questions/questions.component';
import { AppTerms, RegisterComponent } from './pages/register/register.component';
import { ScoreComponent } from './pages/trivia/score/score.component';
import { ShopDataComponent } from './pages/trivia/shop-data/shop-data.component';
import { TriviaComponent } from './pages/trivia/trivia.component';
import { PreviewComponent } from './pages/preview/preview.component';
import { FooterComponent } from './components/footer/footer.component';
import { RedirectComponent } from './components/redirect/redirect.component';
import { CodeComponent } from './pages/code/code.component';



@NgModule({
  declarations: [
    AppComponent,
    RegisterComponent,
    AppTerms,
    TriviaComponent,
    QuestionsComponent,
    ScoreComponent,
    ShopDataComponent,
    AlertComponent,
    PreviewComponent,
    FooterComponent,
    RedirectComponent,
    CodeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ModalModule.forRoot(),
    CollapseModule.forRoot(),
    FormsModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
