<div class="container px-0">
  <div class="row pb-2">
    <div class="col-12 text-center">
      <img src="assets/images/logo_footer.png" alt="logo" />
    </div>

    <div class="col-12 text-center mt-2 small">
      <p class="small mb-1">
        Comunicación para distribución exclusiva a clientes de British American
        Tobacco. México, S.A. de C.V., No colocar en un lugar visible para el
        público en general.
      </p>

      <small class="mb-0">FUMAR ES CAUSA DE CANCER</small>
    </div>
  </div>
</div>
