import { Component } from '@angular/core';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss'],
})
export class RedirectComponent {
  constructor() {
    console.log('redireccionando');
    window.location.href = 'https://www.pointsapp.com.mx/recargas';
  }
}
