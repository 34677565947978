import { Component, OnInit } from '@angular/core';
import { UserService } from './services/user.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ApiService } from './services/api.service';
import { environment } from 'src/environments/environment';
import * as AOS from 'aos';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'DigitalTraining';
  winBg: boolean = false;
  loading: boolean = false;
  canLogout: boolean = false;
  isRegister: boolean = false;

  constructor(
    private user: UserService,
    private router: Router,
    private api: ApiService,
    private route: ActivatedRoute
  ) {
    this.canLogout = environment.logout;

    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.winBg =
          Boolean(this.user.win !== 'false') && event.url == '/resultados';

        this.isRegister = event.url == '/registro';
      }
    });

    if (environment.production) {
      // Insert GTAG
      let el = document.createElement('script');
      el.setAttribute(
        'src',
        'https://www.googletagmanager.com/gtag/js?id=' + environment.analytics
      );
      el.setAttribute('async', 'async');
      document.body.appendChild(el);

      // Insert Analytics
      let gt = document.createElement('script');
      gt.text =
        "window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '" +
        environment.analytics +
        "');";
      document.body.appendChild(gt);
    }
  }

  // Call status service
  ngOnInit(): void {
    AOS.init();
  }

  logout() {
    this.user.clearSession();
    this.router.navigateByUrl('ingresar');
  }
}
