import { Component, Input, AfterViewInit, OnInit } from '@angular/core';
import { AlertModal } from 'src/app/models/Alert.model';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  timerActive: boolean = false; 
  timerRef: any;
  constructorClass: string = 'hidden-alert'

  @Input() time: number = 3000;
  @Input() data: AlertModal = new AlertModal();
  @Input() set timer(value: boolean) {
    this.timerActive = value;
    this.setTimer();
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.constructorClass = '';
    }, 300)
  }
  
  setTimer() {
    clearInterval(this.timerRef);
    
    this.timerRef = setTimeout(() => {
      this.data.active = false;
      this.timerActive = false;
    }, this.time);
  }
}
